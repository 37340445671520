<template>
  <el-button
    class="export-button"
    :class="{
      'border-gray group hover:border-dark-silver p-3': !blue,
      'button-blue': blue
    }"
    :disabled="isExporting"
    @click="$emit('click')"
  >
    <div class="flex">
      <div v-if="content || $slots.default" class="mr-2">
        <slot>{{ content }}</slot>
      </div>
      <div>
        <icon
          :icon="icon"
          :class="{
            'animated loop tada': isExporting,
            'icon-lg text-dark-silver group-hover:text-gunmetal': !blue
          }"
          :spin="isExporting"
        />
      </div>
    </div>
  </el-button>
</template>

<script>
import { download as exportIcon } from '@/vendor/icons';

export default {
  props: {
    blue: Boolean,
    content: {
      type: String,
      default: null
    },
    isExporting: Boolean,
    icon: {
      type: Object,
      default: () => exportIcon
    }
  }
};
</script>
