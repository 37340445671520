<template>
  <section class="flex-1 mt-1">
    <div
      class="border-bottom px-6 py-2 flex items-center border-very-light-gray justify-between"
    >
      <div class="flex flex-1 items-center">
        <icon :icon="searchIcon" class="text-lg ml-2 text-dark-silver" />
        <input
          :value="searchText"
          class="border-none focus:outline-none text-lg w-full text-dark-silver ml-2 placeholder-gray w-full"
          placeholder="Search campuses"
          @input="onSearch($event.target.value)"
        />
        <icon
          v-if="searchText"
          :icon="cancelIcon"
          class="text-dark-silver cursor-pointer mx-4"
          @click="onSearch(null)"
        />
      </div>
      <div class="w-1/4">
        <div class="flex justify-end text-sm w-full items-center">
          <loader-box
            v-if="!audience.availableSummary"
            height="100%"
            class="h-8 w-32"
          />
          <template v-else>
            <tween
              v-if="audience && audience.availableSummary"
              :value="audience.availableSummary.total_campuses || 0"
              class="text-dark-silver mr-4"
            >
              <template v-slot="{ value }">
                {{ value | shortNumber }} Campuses
              </template>
            </tween>
            <tween
              v-if="audience && audience.availableSummary"
              :value="audience.availableSummary.total_enrollment || 0"
              class="text-dark-silver mr-4 "
            >
              <template v-slot="{ value }">
                {{ value | shortNumber }} Students
              </template>
            </tween>
            <export-button
              class="h-11"
              :is-exporting="isExporting"
              @click="onExportTable"
            />
          </template>
        </div>
      </div>
    </div>
    <div v-if="!isEmpty" class="flex p-4 w-full">
      <booking-audience-selections-panel-table
        id="booking-audience-selections-panel-table"
        class="flex w-full"
        :audience="audience"
        :audience-tools="audienceTools"
      />
    </div>
    <empty-state v-else>
      <template #text>
        No campuses found matching your search criteria
      </template>
      <template #subtext>
        Try adjusting your filters or using different search terms.
      </template>
    </empty-state>
  </section>
</template>

<script>
import { debounce } from 'lodash';
import { cancel as cancelIcon, search as searchIcon } from '@/vendor/icons';

import BookingAudienceSelectionsPanelTable from './BookingAudienceSelectionsPanelTable';
import EmptyState from '@/components/Core/Layout/EmptyState';
import ExportButton from '@/components/Core/Table/ExportButton';

export default {
  components: {
    EmptyState,
    ExportButton,
    BookingAudienceSelectionsPanelTable
  },
  props: {
    audience: {
      type: Object,
      required: true
    },
    audienceTools: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      searchIcon,
      cancelIcon,
      isExporting: false,
      campuses: null,
      searchText: null
    };
  },
  computed: {
    isEmpty() {
      return !this.audience.availableSummary?.total_campuses;
    },
    pager() {
      return this.$store.getters['audience/pager'];
    }
  },
  methods: {
    onExportTable() {
      this.$message.success(
        'Campuses are being exported. You will receive an email when it is ready'
      );
    },
    onSearch(text) {
      this.searchText = text;
      this.debounceSearch(this.searchText);
    },
    debounceSearch: debounce(function(like) {
      let pager = { ...this.$store.getters['audience/pager'] };

      pager.filterAvailableCampuses.availableCampusesSearchText = {
        or: {
          name: { like },
          city: { like },
          state: { like },
          'data.state': { like },
          'data.dma': { like }
        }
      };

      this.$store.commit('audience/setPager', { ...pager });
    }, 500)
  }
};
</script>
