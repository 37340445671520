<template>
  <el-dialog
    :title="title"
    :visible="showDialog"
    :append-to-body="true"
    :close-on-click-modal="closeOnClickModal"
    :width="width"
    @update:visible="$emit('close')"
    @open="$emit('open')"
  >
    <slot />

    <template slot="footer">
      <slot name="footer">
        <template v-if="!isSaving">
          <el-button v-if="!hideCancel" @click="$emit('close')">
            <slot name="cancel-text">{{ cancelText }}</slot>
          </el-button>
          <el-button
            v-if="hasSecondary"
            :disabled="isDisabled"
            :class="secondaryClass"
            @click="$emit('secondary')"
          >
            <slot name="secondary-text">{{ secondaryText }}</slot>
          </el-button>
          <el-button
            v-if="!hidePrimary"
            :disabled="isDisabled"
            :class="primaryClass"
            @click="$emit('save')"
          >
            <slot name="save-text">{{ saveText }}</slot>
          </el-button>
        </template>

        <loading-button v-else />
      </slot>
    </template>
  </el-dialog>
</template>

<script>
import LoadingButton from '@/components/Core/Loading/LoadingButton';

export default {
  components: {
    LoadingButton
  },
  props: {
    isDisabled: Boolean,
    hideCancel: Boolean,
    hidePrimary: Boolean,
    hasSecondary: Boolean,
    isSaving: Boolean,
    closeOnClickModal: Boolean,
    onSave: { type: Function, default: null },
    saveText: {
      type: String,
      default: 'Save'
    },
    secondaryText: {
      type: String,
      default: 'Disable'
    },
    cancelText: {
      type: String,
      default: 'Cancel'
    },
    title: {
      type: String,
      default: 'Edit Item'
    },
    width: {
      type: String,
      default: null
    },
    showDialog: {
      type: Boolean,
      default: true
    },
    primaryClass: {
      type: String,
      default: 'button-blue'
    },
    secondaryClass: {
      type: String,
      default: null
    }
  }
};
</script>
