<template>
  <el-container>
    <the-default-layout>
      <the-demo-account-bar slot="header" />
    </the-default-layout>
  </el-container>
</template>

<script>
import TheDefaultLayout from '@/components/Core/Layout/TheDefaultLayout';
import TheDemoAccountBar from './TheDemoAccountBar';

export default {
  components: {
    TheDefaultLayout,
    TheDemoAccountBar
  }
};
</script>
