<template>
  <div class="h-full">
    <div v-if="audience" class="flex h-full w-full relative">
      <div class="h-full border-right border-very-light-gray py-2">
        <notification-icon
          :is-active="true"
          :icon="userIcon"
          :count="audience.summary.total_campuses"
          border-side="right"
          color="blue"
          circle-color="baby-blue"
          class="px-3"
          @click="$emit('togglePanel')"
        />
      </div>

      <div class="h-full w-panel py-6 flex flex-col overflow-hidden">
        <div class="flex justify-center font-semibold">
          My Audience
        </div>
        <empty-state v-if="audience.summary.total_campuses === 0" class="mt-10">
          <template #text>
            Your audience is empty
          </template>
          <template #subtext>
            Select campuses from the left to add to your audience.
          </template>
        </empty-state>
        <!-- <div> -->
        <div v-else>
          <div class="border-bottom border-very-light-gray pb-6 mb-4">
            <loader-box v-if="isLoading" height="6em" class="p-6 w-full" />
            <div
              v-else
              class="flex justify-around items-center text-center text-sm mt-4 mx-1/10"
            >
              <tween :value="audience.summary.total_campuses || 0">
                <template v-slot="{ value }">
                  <div class="text-blue block text-xl font-bold inline">
                    {{ value | shortNumber }}
                  </div>
                  <div class="text-dark-silver">Campuses</div>
                </template>
              </tween>
              <tween :value="audience.summary.total_enrollment || 0">
                <template v-slot="{ value }">
                  <div class="text-blue text-xl font-bold">
                    {{ value | shortNumber }}
                  </div>
                  <div class="text-dark-silver">Students</div>
                </template>
              </tween>
            </div>
          </div>
          <transition name="fade">
            <div class="flex justify-between items-center px-4">
              <div class="flex-grow mr-2 pb-4">
                <transition name="fade" mode="out-in">
                  <el-input
                    placeholder="Search selected campuses..."
                    @input="onSearch"
                  />
                </transition>
              </div>
              <div class="flex items-center pb-4">
                <audience-mutation :id="audience.id">
                  <template v-slot="{ removeCampuses }">
                    <el-button
                      class="border-gray group hover:border-dark-red p-2.5"
                      @click="onRemoveCampuses(removeCampuses)"
                    >
                      <icon
                        :icon="trashEmptyIcon"
                        class="icon-lg"
                        :class="{
                          'text-dark-silver cursor-pointer group-hover:text-dark-red': hasSelections,
                          'text-silver cursor-not-allowed': !hasSelections
                        }"
                      />
                    </el-button>
                  </template>
                </audience-mutation>
                <div class="ml-2">
                  <export-button :disabled="!hasSelections" @click="onExport" />
                </div>
              </div>
            </div>
          </transition>

          <booking-audience-summary-panel-table
            ref="table"
            :audience="audience"
          />
        </div>
      </div>
    </div>

    <loader-box v-else height="100%" class="h-full w-full p-6" />
  </div>
</template>

<script>
import { AudienceMutation } from '@/components/Mutations';
import EmptyState from '@/components/Core/Layout/EmptyState';
import BookingAudienceSummaryPanelTable from './BookingAudienceSummaryPanelTable';
import {
  download as downloadIcon,
  location as locationIcon,
  search as searchIcon,
  trashEmpty as trashEmptyIcon,
  user as userIcon
} from '@/vendor/icons';
import { debounce } from 'lodash';
import ExportButton from '@/components/Core/Table/ExportButton';

export default {
  components: {
    ExportButton,
    AudienceMutation,
    EmptyState,
    BookingAudienceSummaryPanelTable
  },
  props: {
    audience: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      downloadIcon,
      filter: {},
      isExporting: false,
      locationIcon,
      searchIcon,
      trashEmptyIcon,
      userIcon,
      isLoading: false
    };
  },
  computed: {
    hasSelections() {
      return this.audience.selections?.length > 0;
    }
  },
  methods: {
    onSearch: debounce(function(like) {
      let pager = { ...this.$store.getters['audience/pager'] };

      pager.pageCampuses = 1;
      pager.filterCampuses.campusesSearchText = {
        or: {
          name: { like },
          city: { like },
          state: { like },
          'data.state': { like },
          'data.dma': { like }
        }
      };

      this.$store.commit('audience/setPager', { ...pager });
    }, 500),
    onRemoveCampuses(removeCampuses) {
      if (this.audience.selections?.length > 0) {
        removeCampuses(this.audience, this.audience.selections);
      }
    },
    onExport() {
      this.$message.success(
        'Your audience is being exported. You will receive an email when it is ready'
      );
    }
  }
};
</script>
