<script>
import SearchToolsMapperMixin from '@/components/Core/SearchToolsMapperMixin';

export default {
  mixins: [SearchToolsMapperMixin],
  props: {
    value: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      searchToolsConfig: null
    };
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(groups) {
        this.$emit('input', groups);

        const search = this.mapSearchGroups(groups);

        this.$emit('search', search);
      }
    },
    totalFilterCount() {
      let count = 0;

      if (this.value) {
        for (let groupValue of Object.values(this.value)) {
          count += this.filterCount(groupValue);
        }
      }

      return count;
    }
  },
  methods: {
    filterCount(filter) {
      let count = 0;

      if (filter) {
        for (let item of Object.values(filter)) {
          if (item) {
            if (Array.isArray(item)) {
              if (item.length > 0) {
                count++;
              }
            } else {
              let filterValue = item.value || item.searchValue || {};

              let filterValueArray = Array.isArray(filterValue)
                ? filterValue
                : Object.values(filterValue);

              if (
                filterValueArray.some(
                  value => !Array.isArray(value) || (value && value.length)
                )
              ) {
                count++;
              }
            }
          }
        }
      }

      return count;
    }
  }
};
</script>
