<template>
  <audience-mutation :id="audience.id" class="w-full max-h-1/2 min-h-0 px-4">
    <template v-slot="{ updateAudience, selectAllCampuses }">
      <el-table
        ref="table"
        :data="audience.campuses.data"
        header-cell-class-name="text-dark-silver"
        row-key="id"
        class="text-xs-sm el-table-slim"
        @select="onSelect(updateAudience, ...arguments)"
        @select-all="onSelectAll(selectAllCampuses, ...arguments)"
        @sort-change="sortColumns"
      >
        <table-empty-slot
          slot="empty"
          :has-data="!!audience"
          empty-message="No audience selected. Please add some campuses to build your audience."
          error-message="There was a problem loading audience. Please try again later."
        />

        <el-table-column type="selection" reserve-selection width="40" />
        <el-table-column
          class-name="p-0"
          min-width="140"
          label="Campus"
          sortable
          prop="name"
        >
          <template v-slot="{ row }">
            <div class="capitalize">{{ row.name }}</div>
            <div v-if="row.data">
              {{ row.data.enrollment | shortNumber }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          class-name="p-0"
          prop="state"
          sortable
          label="State"
          align="right"
        >
          <template v-slot="{ row }">
            <div class="text-right pr-6">
              {{ row.state }}
            </div>
          </template>
        </el-table-column>
      </el-table>

      <pagination
        v-if="
          audience &&
            audience.campuses &&
            audience.campuses.paginatorInfo.total > 0
        "
        :info="audience.campuses.paginatorInfo"
        :pager.sync="pager"
        :pager-count="5"
        class="my-4 flex flex-wrap-reverse justify-center"
      />
    </template>
  </audience-mutation>
</template>
<script>
import TableEmptySlot from '@/components/Core/Table/TableEmptySlot';
import Pagination from '@/components/Core/Table/Pagination';
import { AudienceMutation } from '@/components/Mutations';
import { sortFilter } from '@/utils/sortFilter';

export default {
  components: {
    TableEmptySlot,
    AudienceMutation,
    Pagination
  },
  props: {
    audience: {
      type: Object,
      default: null
    }
  },
  computed: {
    pager: {
      get() {
        let pager = this.$store.getters['audience/pager'];
        return {
          sort: pager.sortCampuses,
          filter: pager.filterCampuses,
          perPage: pager.perPageCampuses,
          page: pager.pageCampuses
        };
      },
      set(val) {
        let pager = this.$store.getters['audience/pager'];
        this.$store.commit('audience/setPager', {
          ...pager,
          filterCampuses: val.filter,
          sortCampuses: val.sort,
          perPageCampuses: val.perPage,
          pageCampuses: val.page
        });
      }
    }
  },
  watch: {
    audience() {
      this.syncRowSelection();
    }
  },
  mounted() {
    this.syncRowSelection();
  },
  methods: {
    ...sortFilter,
    onSelect(updateAudience, selection, row) {
      let selections = this.audience.selections
        ? this.audience.selections.slice()
        : [];
      if (selections.includes(row.id)) {
        selections = selections.filter(s => s !== row.id);
      } else {
        selections.push(row.id);
      }
      updateAudience(this.audience, { selections });
    },
    onSelectAll(selectAllCampuses, selection) {
      const selectAll = selection.length !== 0;
      selectAllCampuses(this.audience, selectAll);
    },
    syncRowSelection() {
      this.$nextTick(() => {
        if (this.audience.selections?.length) {
          for (let campus of this.audience.campuses.data) {
            this.$refs.table.toggleRowSelection(
              campus,
              this.audience.selections.indexOf(campus.id) >= 0
            );
          }
        } else {
          this.$refs.table.clearSelection();
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
/deep/ .el-pagination__sizes {
  display: block;
  width: 100%;
  margin-top: 1em;
}

/deep/ .el-table {
  .p-0 {
    .cell {
      padding: 0 !important;
    }
  }
}
</style>
