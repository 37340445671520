var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('audience-mutation',{staticClass:"w-full max-h-1/2 min-h-0 px-4",attrs:{"id":_vm.audience.id},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var updateAudience = ref.updateAudience;
var selectAllCampuses = ref.selectAllCampuses;
return [_c('el-table',{ref:"table",staticClass:"text-xs-sm el-table-slim",attrs:{"data":_vm.audience.campuses.data,"header-cell-class-name":"text-dark-silver","row-key":"id"},on:{"select":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.onSelect.apply(void 0, [ updateAudience ].concat( argsArray ))},"select-all":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.onSelectAll.apply(void 0, [ selectAllCampuses ].concat( argsArray ))},"sort-change":_vm.sortColumns}},[_c('table-empty-slot',{attrs:{"slot":"empty","has-data":!!_vm.audience,"empty-message":"No audience selected. Please add some campuses to build your audience.","error-message":"There was a problem loading audience. Please try again later."},slot:"empty"}),_c('el-table-column',{attrs:{"type":"selection","reserve-selection":"","width":"40"}}),_c('el-table-column',{attrs:{"class-name":"p-0","min-width":"140","label":"Campus","sortable":"","prop":"name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"capitalize"},[_vm._v(_vm._s(row.name))]),(row.data)?_c('div',[_vm._v(" "+_vm._s(_vm._f("shortNumber")(row.data.enrollment))+" ")]):_vm._e()]}}],null,true)}),_c('el-table-column',{attrs:{"class-name":"p-0","prop":"state","sortable":"","label":"State","align":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"text-right pr-6"},[_vm._v(" "+_vm._s(row.state)+" ")])]}}],null,true)})],1),(
        _vm.audience &&
          _vm.audience.campuses &&
          _vm.audience.campuses.paginatorInfo.total > 0
      )?_c('pagination',{staticClass:"my-4 flex flex-wrap-reverse justify-center",attrs:{"info":_vm.audience.campuses.paginatorInfo,"pager":_vm.pager,"pager-count":5},on:{"update:pager":function($event){_vm.pager=$event}}}):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }