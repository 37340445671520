var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"h-full",attrs:{"id":"page-booking-audience"}},[_c('audience-query',{attrs:{"id":_vm.audienceId,"loading":_vm.isLoading},on:{"update:loading":function($event){_vm.isLoading=$event},"data":function($event){_vm.audience = $event}}}),_c('audience-mutation',{staticClass:"h-full",attrs:{"id":_vm.audience && _vm.audience.id},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isSaving = ref.isSaving;
var addCampuses = ref.addCampuses;
var addCampusesByName = ref.addCampusesByName;
return [_c('booking-page',{attrs:{"has-right-panel":_vm.hasRightPanel,"has-left-panel":_vm.hasLeftPanel},on:{"update:hasRightPanel":function($event){_vm.hasRightPanel=$event},"update:has-right-panel":function($event){_vm.hasRightPanel=$event},"update:hasLeftPanel":function($event){_vm.hasLeftPanel=$event},"update:has-left-panel":function($event){_vm.hasLeftPanel=$event}},scopedSlots:_vm._u([{key:"header",fn:function(){return [(!_vm.audience)?_c('loader-box',{staticClass:"w-56 h-14 p-2 pr-4",attrs:{"height":"100%"}}):_c('div',{staticClass:"flex justify-end w-1/2"},[_c('div',{staticClass:"h-full flex items-center"},[_c('div',{staticClass:"flex items-center p-4"},[(_vm.isLoading)?_c('loader-box',{staticClass:"w-56 h-14 p-2 pr-4",attrs:{"height":"100%"}}):[(isSaving)?[_c('loading-button',{staticClass:"w-20"})]:[_c('el-button',{staticClass:"button-blue mr-3",on:{"click":function($event){_vm.showBulkAddAudienceDialog = true}}},[_c('icon',{attrs:{"icon":_vm.bulkAddAudienceIcon}})],1),_c('el-button',{staticClass:"button-green",attrs:{"disabled":!_vm.audience ||
                          !_vm.audience.available_selections ||
                          !_vm.audience.available_selections.length},on:{"click":function($event){return addCampuses(_vm.audience, _vm.audience.available_selections)}}},[_c('tween',{attrs:{"value":_vm.audience && _vm.audience.available_selections
                            ? _vm.audience.available_selections.length
                            : 0},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var value = ref.value;
return [_vm._v(" Add "+_vm._s(_vm._f("shortNumber")(value))+" "+_vm._s(value === 1 ? 'Campus' : 'Campuses')+" To Audience ")]}}],null,true)})],1)]]],2),(!_vm.hasRightPanel)?_c('div',{staticClass:"border-left ml-4 p-4 h-full flex items-center"},[_c('notification-icon',{attrs:{"is-active":true,"icon":_vm.userIcon,"count":_vm.audience && _vm.audience.summary
                      ? _vm.audience.summary.total_campuses
                      : 400,"color":"blue","circle-color":"white"},on:{"click":function($event){_vm.hasRightPanel = !_vm.hasRightPanel}}})],1):_vm._e()])]),(_vm.showBulkAddAudienceDialog)?_c('edit-dialog',{attrs:{"title":"Add Multiple Campuses","save-text":"Add Campuses","is-saving":isSaving},on:{"save":function($event){return _vm.bulkAddCampuses(addCampusesByName)},"close":function($event){_vm.showBulkAddAudienceDialog = false}}},[_c('div',{staticClass:"text-dark-silver mb-5"},[_vm._v(" Add a new line separated list of campus name. The names must match exactly! ")]),_c('el-input',{attrs:{"value":_vm.campusNameList.join('\n'),"type":"textarea","rows":4,"placeholder":"Enter Campus Names..."},on:{"input":function($event){_vm.campusNameList = $event.split('\n')}}})],1):_vm._e()]},proxy:true},{key:"leftPanel",fn:function(ref){
                      var isCollapsed = ref.isCollapsed;
return [(!_vm.audience)?_c('loader-box',{staticClass:"w-full h-full",attrs:{"height":"100%"}}):_c('the-booking-audience-builder-panel',{attrs:{"audience":_vm.audience,"value":_vm.audienceTools,"is-collapsed":isCollapsed},on:{"input":_vm.onAudienceBuilderTools,"collapse":function($event){_vm.hasLeftPanel = true}}})]}},{key:"rightPanel",fn:function(){return [(!_vm.audience)?_c('loader-box',{staticClass:"w-full h-full",attrs:{"height":"100%"}}):_c('the-booking-audience-summary-panel',{attrs:{"audience":_vm.audience},on:{"togglePanel":function($event){_vm.hasRightPanel = !_vm.hasRightPanel}}})]},proxy:true}],null,true)},[[_c('div',{staticClass:"h-full flex-1 overflow-x-auto"},[(!_vm.audience)?_c('loader-box',{staticClass:"w-full h-full",attrs:{"height":"100%"}}):_c('the-booking-audience-selections-panel',{staticClass:"w-full overflow-y-auto",attrs:{"audience":_vm.audience,"audience-tools":_vm.audienceTools}})],1)]],2)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }