<template>
  <section class="w-full">
    <div class="flex m-2">
      <notification-icon
        circle-color="baby-blue"
        :is-active="true"
        border-side="bottom"
        :icon="slidersIcon"
        :count="totalFilterCount"
        class="my-2"
        @click="$emit('collapse')"
      />
    </div>

    <div v-show="isCollapsed" class="cursor-default">
      <div
        v-for="group in SearchGroups"
        :key="`collapsed-icon-${group.name}`"
        class="flex justify-center"
      >
        <icon
          :icon="group.icon"
          :class="{
            [`text-${group.color}`]: filterCount(value[group.name]) > 0,
            'text-dark-silver': filterCount(value[group.name]) === 0
          }"
          class="text-xl my-2"
        />
      </div>
    </div>

    <audience-search-tools-query
      v-show="!isCollapsed"
      :id="audience.id"
      class="h-full"
      @data="searchToolsConfig = $event.searchTools"
    >
      <template v-slot="{ isLoading }">
        <el-collapse>
          <el-collapse-item
            v-for="group in SearchGroups"
            :key="`collapse-item-${group.name}`"
          >
            <template v-slot:title>
              <div class="flex flex-col">
                <loader-box v-if="isLoading" height="2em" class="px-6 py-3" />
                <div
                  v-else
                  class="cursor-pointer flex items-center justify-between pl-6 pr-4"
                >
                  <span
                    >{{ group.label }}
                    <el-tooltip
                      v-if="group.info"
                      effect="light"
                      placement="right"
                      :content="group.info"
                    >
                      <icon
                        :icon="infoCircledIcon"
                        class="text-dark-silver ml-2"
                      />
                    </el-tooltip>
                  </span>
                  <el-tag
                    class="my-2 text-dark-silver"
                    size="small"
                    :type="group.tagType"
                    >{{ filterCount(value[group.name]) }} Applied
                  </el-tag>
                </div>
              </div>
            </template>

            <template>
              <loader-box v-if="isLoading" height="4em" class="px-6 py-3" />

              <search-group
                v-else-if="
                  searchToolsConfig &&
                    searchToolsConfig.find(st => st.name === group.name)
                "
                :value="model[group.name]"
                :group="searchToolsConfig.find(st => st.name === group.name)"
                class="px-6"
                @input="model = { ...model, ...{ [group.name]: $event } }"
              />
            </template>
          </el-collapse-item>
        </el-collapse>
      </template>
    </audience-search-tools-query>
  </section>
</template>

<script>
import { AudienceSearchToolsQuery } from '@/components/Queries';
import {
  infoCircled as infoCircledIcon,
  sliders as slidersIcon
} from '@/vendor/icons';
import SearchGroup from '@/components/Core/SearchGroup';
import { AdminAudienceSearchGroups, AudienceSearchGroups } from '@/constants';
import SearchGroupSidebar from '@/components/Core/SearchGroupSidebar';

export default {
  components: {
    AudienceSearchToolsQuery,
    SearchGroup
  },
  mixins: [SearchGroupSidebar],
  props: {
    audience: {
      type: Object,
      required: true
    },
    isCollapsed: Boolean
  },
  data() {
    return {
      infoCircledIcon,
      slidersIcon,
      AdminAudienceSearchGroups,
      AudienceSearchGroups,
      searchToolsConfig: null
    };
  },
  computed: {
    SearchGroups() {
      if (this.$can('edit_national_campaigns')) {
        return this.AdminAudienceSearchGroups;
      } else {
        return this.AudienceSearchGroups;
      }
    }
  }
};
</script>
