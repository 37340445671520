<template>
  <audience-mutation
    :id="audience.id"
    class="w-full flex flex-col cursor-pointer select-none"
  >
    <template v-slot="{ updateAudience, selectAllAvailableCampuses }">
      <el-table
        ref="table"
        class="text-sm text-dark-silver"
        header-cell-class-name="text-dark-silver"
        type="selection"
        :data="audience.availableCampuses.data"
        row-key="id"
        :default-sort="defaultSort"
        @select="onSelect(updateAudience, ...arguments)"
        @select-all="onSelectAll(selectAllAvailableCampuses, ...arguments)"
        @sort-change="sortColumns"
        @row-click="onRowClick(updateAudience, ...arguments)"
      >
        <template v-slot:empty>
          <table-empty-slot
            :has-data="!!audience.availableCampuses"
            empty-message="No campuses found"
            error-message="There was a problem loading the campuses. Please try again later."
          />
        </template>
        <el-table-column type="selection" reserve-selection width="40" />
        <el-table-column prop="name" label="Campus" min-width="120" sortable>
          <template v-slot="{ row }">
            <div class="capitalize">{{ row.name }}</div>
          </template>
        </el-table-column>
        <el-table-column
          label="Students"
          min-width="100"
          prop="data.enrollment"
          sortable
        >
          <template v-slot="{ row }">
            <span v-if="row && row.data">{{
              row.data.enrollment | shortNumber
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="Staff"
          min-width="75"
          prop="data.staff"
          sortable
        >
          <template v-slot="{ row }">
            <span v-if="row && row.data">{{
              row.data.staff | shortNumber
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="city" label="City" min-width="75" sortable />
        <el-table-column prop="state" label="State" min-width="75" sortable />
        <el-table-column
          label="Relevance"
          prop="relevance"
          min-width="105"
          sortable
        >
          <template v-slot="{ row }">
            <booking-audience-relevance-column
              :row="row"
              :audience-tools="audienceTools"
            />
          </template>
        </el-table-column>
      </el-table>

      <pagination
        v-if="
          audience &&
            audience.availableCampuses &&
            audience.availableCampuses.paginatorInfo.total > 0
        "
        :info="audience.availableCampuses.paginatorInfo"
        :pager.sync="pager"
        class="my-4"
      />
    </template>
  </audience-mutation>
</template>

<script>
import Pagination from '@/components/Core/Table/Pagination';
import TableEmptySlot from '@/components/Core/Table/TableEmptySlot';
import { AudienceMutation } from '@/components/Mutations';
import BookingAudienceRelevanceColumn from './BookingAudienceRelevanceColumn';

export default {
  components: {
    Pagination,
    AudienceMutation,
    TableEmptySlot,
    BookingAudienceRelevanceColumn
  },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    audience: {
      type: Object,
      required: true
    },
    audienceTools: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      selectionRange: [null, null],
      isShiftKeyDown: false
    };
  },
  computed: {
    pager: {
      get() {
        let pager = this.$store.getters['audience/pager'];
        return {
          sort: pager.sortAvailableCampuses,
          filter: pager.filterAvailableCampuses,
          perPage: pager.perPageAvailableCampuses,
          page: pager.pageAvailableCampuses
        };
      },
      set(val) {
        let pager = this.$store.getters['audience/pager'];
        this.$store.commit('audience/setPager', {
          ...pager,
          filterAvailableCampuses: val.filter,
          sortAvailableCampuses: val.sort,
          perPageAvailableCampuses: val.perPage,
          pageAvailableCampuses: val.page
        });
      }
    },
    defaultSort() {
      let sort = this.pager.sort[0];

      if (sort) {
        return {
          prop: sort.column,
          order: sort.order
        };
      } else {
        return {};
      }
    }
  },
  watch: {
    audience() {
      this.syncRowSelection();
    }
  },
  mounted() {
    this.syncRowSelection();
  },
  methods: {
    onSelect(updateAudience, selection, row) {
      updateAudience(this.audience, {
        available_selections: this.getAvailableSelections([row.id])
      });
    },
    onSelectAll(selectAllAvailableCampuses, selection) {
      const selectAll = selection.length !== 0;
      selectAllAvailableCampuses(this.audience, selectAll);
    },
    syncRowSelection() {
      this.$nextTick(() => {
        if (this.audience.available_selections?.length) {
          for (let campus of this.audience.availableCampuses.data) {
            this.$refs.table.toggleRowSelection(
              campus,
              this.audience.available_selections.indexOf(campus.id) >= 0
            );
          }
        } else {
          this.$refs.table.clearSelection();
        }
      });
    },
    sortColumns(col) {
      let sort = [];

      if (col.column) {
        if (col.prop === 'relevance') {
          sort = {
            '"`relevance`"': col.order === 'ascending' ? 'asc' : 'desc'
          };
        } else {
          sort = [
            {
              column: col.column.sortBy || col.column.property,
              order: col.order
            }
          ];
        }
      }

      this.pager = {
        ...this.pager,
        sort
      };
    },
    onRowClick(updateAudience, row, event) {
      let selections = event.shiftKey ? [] : [row.id];
      if (event.shiftKey) {
        if (this.selectionRange[0] === null) {
          this.$set(
            this.selectionRange,
            0,
            this.audience.availableCampuses.data.findIndex(
              as => as.id === row.id
            )
          );
          return;
        }
        if (this.selectionRange[1] === null) {
          this.$set(
            this.selectionRange,
            1,
            this.audience.availableCampuses.data.findIndex(
              as => as.id === row.id
            )
          );
        }
        if (this.selectionRange.some(s => s === null)) return;
        this.selectionRange.sort((a, b) => a - b);
        for (let i = this.selectionRange[0]; i <= this.selectionRange[1]; i++) {
          selections.push(this.audience.availableCampuses.data[i].id);
        }
        this.selectionRange = [null, null];
      }
      let available_selections = this.getAvailableSelections(selections);
      updateAudience(this.audience, { available_selections });
    },
    getAvailableSelections(selections) {
      let availableSelections = this.audience.available_selections
        ? this.audience.available_selections.slice()
        : [];
      selections.forEach(selection => {
        if (availableSelections.includes(selection)) {
          availableSelections = availableSelections.filter(
            s => s !== selection
          );
        } else {
          availableSelections.push(selection);
        }
      });
      return availableSelections;
    }
  }
};
</script>

<style lang="scss" scoped>
/deep/ .el-table__body-wrapper {
  overflow-y: auto;
  height: 100%;
}
</style>
