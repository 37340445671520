var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('audience-mutation',{staticClass:"w-full flex flex-col cursor-pointer select-none",attrs:{"id":_vm.audience.id},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var updateAudience = ref.updateAudience;
var selectAllAvailableCampuses = ref.selectAllAvailableCampuses;
return [_c('el-table',{ref:"table",staticClass:"text-sm text-dark-silver",attrs:{"header-cell-class-name":"text-dark-silver","type":"selection","data":_vm.audience.availableCampuses.data,"row-key":"id","default-sort":_vm.defaultSort},on:{"select":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.onSelect.apply(void 0, [ updateAudience ].concat( argsArray ))},"select-all":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.onSelectAll.apply(void 0, [ selectAllAvailableCampuses ].concat( argsArray ))},"sort-change":_vm.sortColumns,"row-click":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.onRowClick.apply(void 0, [ updateAudience ].concat( argsArray ))}},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('table-empty-slot',{attrs:{"has-data":!!_vm.audience.availableCampuses,"empty-message":"No campuses found","error-message":"There was a problem loading the campuses. Please try again later."}})]},proxy:true}],null,true)},[_c('el-table-column',{attrs:{"type":"selection","reserve-selection":"","width":"40"}}),_c('el-table-column',{attrs:{"prop":"name","label":"Campus","min-width":"120","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"capitalize"},[_vm._v(_vm._s(row.name))])]}}],null,true)}),_c('el-table-column',{attrs:{"label":"Students","min-width":"100","prop":"data.enrollment","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row && row.data)?_c('span',[_vm._v(_vm._s(_vm._f("shortNumber")(row.data.enrollment)))]):_vm._e()]}}],null,true)}),_c('el-table-column',{attrs:{"label":"Staff","min-width":"75","prop":"data.staff","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row && row.data)?_c('span',[_vm._v(_vm._s(_vm._f("shortNumber")(row.data.staff)))]):_vm._e()]}}],null,true)}),_c('el-table-column',{attrs:{"prop":"city","label":"City","min-width":"75","sortable":""}}),_c('el-table-column',{attrs:{"prop":"state","label":"State","min-width":"75","sortable":""}}),_c('el-table-column',{attrs:{"label":"Relevance","prop":"relevance","min-width":"105","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('booking-audience-relevance-column',{attrs:{"row":row,"audience-tools":_vm.audienceTools}})]}}],null,true)})],1),(
        _vm.audience &&
          _vm.audience.availableCampuses &&
          _vm.audience.availableCampuses.paginatorInfo.total > 0
      )?_c('pagination',{staticClass:"my-4",attrs:{"info":_vm.audience.availableCampuses.paginatorInfo,"pager":_vm.pager},on:{"update:pager":function($event){_vm.pager=$event}}}):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }