<template>
  <div id="page-booking-audience" class="h-full">
    <audience-query
      :id="audienceId"
      :loading.sync="isLoading"
      @data="audience = $event"
    />

    <audience-mutation :id="audience && audience.id" class="h-full">
      <template v-slot="{ isSaving, addCampuses, addCampusesByName }">
        <booking-page
          :has-right-panel.sync="hasRightPanel"
          :has-left-panel.sync="hasLeftPanel"
        >
          <template #header>
            <loader-box
              v-if="!audience"
              height="100%"
              class="w-56 h-14 p-2 pr-4"
            />
            <div v-else class="flex justify-end w-1/2">
              <div class="h-full flex items-center">
                <div class="flex items-center p-4">
                  <loader-box
                    v-if="isLoading"
                    height="100%"
                    class="w-56 h-14 p-2 pr-4"
                  />
                  <template v-else>
                    <template v-if="isSaving">
                      <loading-button class="w-20" />
                    </template>
                    <template v-else>
                      <el-button
                        class="button-blue mr-3"
                        @click="showBulkAddAudienceDialog = true"
                      >
                        <icon :icon="bulkAddAudienceIcon" />
                      </el-button>
                      <el-button
                        class="button-green"
                        :disabled="
                          !audience ||
                            !audience.available_selections ||
                            !audience.available_selections.length
                        "
                        @click="
                          addCampuses(audience, audience.available_selections)
                        "
                      >
                        <tween
                          :value="
                            audience && audience.available_selections
                              ? audience.available_selections.length
                              : 0
                          "
                        >
                          <template v-slot="{ value }">
                            Add {{ value | shortNumber }}
                            {{ value === 1 ? 'Campus' : 'Campuses' }} To
                            Audience
                          </template>
                        </tween>
                      </el-button>
                    </template>
                  </template>
                </div>

                <div
                  v-if="!hasRightPanel"
                  class="border-left ml-4 p-4 h-full flex items-center"
                >
                  <notification-icon
                    :is-active="true"
                    :icon="userIcon"
                    :count="
                      audience && audience.summary
                        ? audience.summary.total_campuses
                        : 400
                    "
                    color="blue"
                    circle-color="white"
                    @click="hasRightPanel = !hasRightPanel"
                  />
                </div>
              </div>
            </div>

            <edit-dialog
              v-if="showBulkAddAudienceDialog"
              title="Add Multiple Campuses"
              save-text="Add Campuses"
              :is-saving="isSaving"
              @save="bulkAddCampuses(addCampusesByName)"
              @close="showBulkAddAudienceDialog = false"
            >
              <div class="text-dark-silver mb-5">
                Add a new line separated list of campus name. The names must
                match exactly!
              </div>
              <el-input
                :value="campusNameList.join('\n')"
                type="textarea"
                :rows="4"
                placeholder="Enter Campus Names..."
                @input="campusNameList = $event.split('\n')"
              />
            </edit-dialog>
          </template>

          <template #leftPanel="{isCollapsed}">
            <loader-box v-if="!audience" height="100%" class="w-full h-full" />
            <the-booking-audience-builder-panel
              v-else
              :audience="audience"
              :value="audienceTools"
              :is-collapsed="isCollapsed"
              @input="onAudienceBuilderTools"
              @collapse="hasLeftPanel = true"
            />
          </template>

          <template>
            <div class="h-full flex-1 overflow-x-auto">
              <loader-box
                v-if="!audience"
                height="100%"
                class="w-full h-full"
              />
              <the-booking-audience-selections-panel
                v-else
                :audience="audience"
                :audience-tools="audienceTools"
                class="w-full overflow-y-auto"
              />
            </div>
          </template>

          <template #rightPanel>
            <loader-box v-if="!audience" height="100%" class="w-full h-full" />
            <the-booking-audience-summary-panel
              v-else
              :audience="audience"
              @togglePanel="hasRightPanel = !hasRightPanel"
            />
          </template>
        </booking-page>
      </template>
    </audience-mutation>
  </div>
</template>

<script>
import BookingPage from '@/modules/Demo/Audience/components/BookingPage';
import TheBookingAudienceBuilderPanel from './components/TheBookingAudienceBuilderPanel';
import TheBookingAudienceSelectionsPanel from './components/TheBookingAudienceSelectionsPanel';
import TheBookingAudienceSummaryPanel from './components/TheBookingAudienceSummaryPanel';
import { AudienceMutation } from '@/components/Mutations';
import {
  upload as bulkAddAudienceIcon,
  user as userIcon
} from '@/vendor/icons';
import EditDialog from '@/components/Core/EditDialog';
import { AudienceQuery } from '@/components/Queries';

export default {
  components: {
    AudienceQuery,
    EditDialog,
    BookingPage,
    TheBookingAudienceBuilderPanel,
    TheBookingAudienceSelectionsPanel,
    TheBookingAudienceSummaryPanel,
    AudienceMutation
  },
  props: {
    audienceId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isLoading: false,
      hasRightPanel: true,
      hasLeftPanel: true,
      audience: null,
      audienceTools: {
        Setting: {},
        Location: {},
        Filter: {},
        Preference: {}
      },
      showBulkAddAudienceDialog: false,
      campusNameList: [],

      // Icons
      bulkAddAudienceIcon,
      userIcon
    };
  },
  computed: {
    filter() {
      let filter = {};
      let groups = [
        this.audienceTools.Filter,
        this.audienceTools.Location,
        this.audienceTools.Setting
      ];
      groups.forEach(group => {
        Object.values(group).forEach(toolData => {
          filter = {
            ...filter,
            ...toolData.searchValue
          };
          if (
            toolData.value === null ||
            (Array.isArray(toolData.value) && toolData.value.length === 0)
          ) {
            for (let prop in toolData.searchValue) {
              this.$delete(filter, prop);
            }
          }
        });
      });
      return filter;
    },
    preference() {
      let preference = {};
      Object.values(this.audienceTools.Preference).forEach(toolData => {
        preference = {
          ...preference,
          ...toolData.searchValue
        };
      });

      return preference;
    },
    sort() {
      if (Object.keys(this.preference).length > 0) {
        return { '`relevance`': 'desc' };
      } else {
        return [{ column: 'data.enrollment', order: 'descending' }];
      }
    }
  },
  methods: {
    async bulkAddCampuses(addCampusesByName) {
      const result = await addCampusesByName(
        this.audience,
        this.campusNameList
      );
      this.showBulkAddAudienceDialog = false;
      this.campusNameList = [];

      this.$message.success(
        `${result.mutateAudience.addCampusesByName} matching campuses were added to the Audience`
      );
    },
    setAudienceTools(audienceTools) {
      Object.values(audienceTools).forEach(group => {
        Object.keys(group).forEach(key => {
          let toolData = group[key];
          if (
            toolData.value === undefined ||
            (Array.isArray(toolData.value) && toolData.value.length === 0)
          ) {
            this.$delete(group, toolData.tool.name);
          }
        });
      });
      this.audienceTools = audienceTools;
    },
    onAudienceBuilderTools(audienceTools) {
      this.setAudienceTools(audienceTools);

      let pager = this.$store.getters['audience/pager'];

      this.$store.commit('audience/setPager', {
        ...pager,
        pageAvailableCampuses: 1,
        filterAvailableCampuses: this.filter,
        sortAvailableCampuses: this.sort,
        preference: this.preference
      });
    }
  }
};
</script>
