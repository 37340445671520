<template>
  <div class="bg-very-light-gray p-3.5 pb-0 flex h-full w-full">
    <div
      class="bg-white shadow-gray-1 rounded w-3/4 flex-grow flex flex-col h-full"
    >
      <div
        :class="{ 'rounded-tr': !hasLeftPanel }"
        class="flex items-center justify-between bg-white rounded-tl shadow-gray-2 w-full"
      >
        <div class="capitalize py-2 px-6 min-w-15 font-semibold">
          <slot name="title">
            {{ title || this.$route.meta.title || 'Browse Campuses' }}
          </slot>
        </div>
        <slot name="header" />
      </div>
      <div class="h-full w-full overflow-hidden mt-1 flex max-w-full">
        <aside
          v-if="$scopedSlots.leftPanel"
          :class="{ 'is-collapsed': !hasLeftPanel }"
          class="relative w-panel overflow-auto border-right flex-none"
        >
          <icon
            v-if="hasLeftPanel"
            :icon="cancelIcon"
            class="text-dark-silver absolute top-0 right-0 m-6 cursor-pointer text-xxs"
            @click="toggleLeftPanel"
          />
          <div
            class="flex w-full"
            :class="[!hasLeftPanel ? 'justify-center' : 'justify-start']"
          >
            <slot name="leftPanel" :isCollapsed="!hasLeftPanel" />
          </div>
          <footer
            v-if="!hasLeftPanel"
            class="mt-2 pb-4 absolute bottom-0 w-full flex justify-center bg-white"
          >
            <icon
              :icon="angleDoubleRightIcon"
              class="text-xl text-dark-silver cursor-pointer"
              @click="toggleLeftPanel"
            />
          </footer>
        </aside>
        <slot />
      </div>
    </div>
    <div
      :class="{ 'right-panel': hasRightPanel, 'w-0': !hasRightPanel }"
      class="transition-width-.3 bg-white flex flex-col overflow-hidden rounded-r side-panel shadow-gray-3 relative"
    >
      <icon
        :icon="cancelIcon"
        class="text-dark-silver absolute top-0 right-0 m-6 text-xxs cursor-pointer z-10"
        @click="toggleRightPanel"
      />
      <slot name="rightPanel" :hasRightPanel="hasRightPanel" />
    </div>
  </div>
</template>

<script>
import {
  angleDoubleRight as angleDoubleRightIcon,
  cancel as cancelIcon
} from '@/vendor/icons';

export default {
  props: {
    hasLeftPanel: Boolean,
    hasRightPanel: Boolean,
    hidePanelsOnMount: Boolean,
    title: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      angleDoubleRightIcon,
      cancelIcon
    };
  },
  mounted() {
    if (!this.hidePanelsOnMount) {
      this.$emit('update:hasRightPanel', !!this.$scopedSlots.rightPanel);
      this.$emit('update:hasLeftPanel', !!this.$scopedSlots.leftPanel);
    }
  },
  methods: {
    toggleRightPanel() {
      this.$emit('update:hasRightPanel', !this.hasRightPanel);
    },
    toggleLeftPanel() {
      this.$emit('update:hasLeftPanel', !this.hasLeftPanel);
    }
  }
};
</script>

<style lang="scss" scoped>
aside {
  transition: width 0.3s ease-in-out;

  &.is-collapsed {
    width: 4em;
  }
}

.right-panel {
  width: 360px;
}
</style>
