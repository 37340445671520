<template>
  <div id="empty-state" class="flex-col mx-10">
    <div class="text-center font-semibold max-w-sm mx-auto">
      <div>
        <slot name="text" />
      </div>
      <div class="text-center text-dark-silver text-md">
        <slot name="subtext" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: null
    },
    subtext: {
      type: String,
      default: null
    }
  }
};
</script>
