<template>
  <el-pagination
    :page-size="info.perPage"
    :page-sizes="overridePageSizes"
    :total="info.total"
    :current-page="info.currentPage"
    :pager-count="pagerCount"
    :layout="info.hideSizes ? 'prev,pager,next' : 'sizes,prev,pager,next'"
    @size-change="onSizeChange"
    @current-change="onCurrentChange"
  />
</template>

<script>
export default {
  props: {
    info: {
      type: Object,
      required: true
    },
    pager: {
      type: Object,
      required: true
    },
    pagerCount: { type: Number, default: 7 },
    pageSizes: {
      type: Array,
      default() {
        return [5, 10, 20, 50];
      }
    }
  },

  computed: {
    overridePageSizes() {
      return [
        ...this.pageSizes,
        ...(this.pageSizes.includes(this.pager.perPage)
          ? []
          : [this.pager.perPage])
      ].sort((a, b) => (+a < +b ? -1 : 1));
    }
  },
  methods: {
    onSizeChange(perPage) {
      this.$emit('update:pager', { ...this.pager, perPage });
    },

    onCurrentChange(page) {
      this.$emit('update:pager', { ...this.pager, page });
    }
  }
};
</script>
