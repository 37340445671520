<template>
  <div class="flex items-center">
    <el-rate
      :value="relevance"
      disabled
      :icon-classes="['el-icon-bar-on', 'el-icon-bar-on', 'el-icon-bar-on']"
      disabled-void-icon-class="el-icon-bar-off"
    />
    <div class="flex items-center flex-wrap">
      <div v-if="isAnyToolSelected" class="ml-2">
        <el-popover placement="bottom" :title="row.name" trigger="hover">
          <icon
            slot="reference"
            :icon="infoCircledIcon"
            class="text-md text-dark-silver cursor-pointer"
          />
          <template v-for="group in SearchGroups">
            <div
              v-for="toolData in audienceTools[group.name]"
              :key="`${row.id}-popover-${group.name}-tag-${toolData.label}`"
              class="w-full my-2"
            >
              <el-tag
                v-if="getTagText(toolData)"
                class="text-dark-silver"
                :type="group.tagType"
              >
                {{ getTagText(toolData) }}
              </el-tag>
            </div>
          </template>
        </el-popover>
      </div>
      <div
        v-if="hasSupplierStatuses && $can('edit_national_campaigns')"
        class="ml-2"
      >
        <el-popover placement="bottom" trigger="hover">
          <icon
            slot="reference"
            :icon="flytedeskIcon"
            class="icon-md text-dark-silver cursor-pointer"
          />
          <template v-for="supplier in row.suppliers">
            <div
              v-if="supplier.supply_status"
              :key="`${row.id}-popover-${supplier.name}`"
              class="mb-2 flex flex-col text-black"
            >
              {{ supplier.name }}
              <div class="mt-2">
                <el-tag type="info">
                  {{ supplier.supply_status }}
                </el-tag>
              </div>
            </div>
          </template>
        </el-popover>
      </div>
    </div>
  </div>
</template>

<script>
import {
  flytedesk as flytedeskIcon,
  heart as heartIcon,
  infoCircled as infoCircledIcon,
  ok as okIcon
} from '@/vendor/icons';
import {
  AdminAudienceSearchGroups,
  AudienceSearchGroups,
  SearchDataTypes,
  SearchToolDataTypes
} from '@/constants';
import { concat, intersection, uniq } from 'lodash';

export default {
  props: {
    row: {
      type: Object,
      default: null
    },
    audienceTools: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      flytedeskIcon,
      okIcon,
      heartIcon,
      infoCircledIcon,
      AdminAudienceSearchGroups,
      AudienceSearchGroups,
      SearchDataTypes
    };
  },
  computed: {
    relevance() {
      if (this.row.relevance) {
        return (this.row.relevance * 100) / 20;
      }
      return 0;
    },
    hasSupplierStatuses() {
      return this.row && this.row.suppliers.find(pub => pub.supply_status);
    },
    isAnyToolSelected() {
      return Object.values(this.audienceTools)
        .filter(
          tool =>
            !Object.values(tool).some(
              tool => tool.tool && tool.tool.data.hideRelevanceScore
            )
        )
        .some(tool => Object.keys(tool).length > 0);
    },
    SearchGroups() {
      if (this.$store.getters['auth/isAdmin']) {
        return this.AdminAudienceSearchGroups;
      } else {
        return this.AudienceSearchGroups;
      }
    }
  },
  methods: {
    getTagText(toolData) {
      if (
        !this.row ||
        !this.row.data ||
        toolData.tool.data.hideRelevanceScore
      ) {
        return;
      }
      let prop;
      if (toolData.tool.data.useKeyAsIndex) {
        prop = toolData.tool.data.index;
      } else {
        if (toolData.tool.data.indexFrom) {
          prop = toolData.tool.data.indexFrom.replace('data.', '');
        } else {
          prop = toolData.tool.data.index.replace('data.', '');
        }
      }

      let value = this.row[prop] || this.row.data[prop];

      if (toolData.tool.label === 'Media Offered') {
        value = this.prepareMediaOfferedTagValue(toolData);
      }

      if (!value) {
        value = Array.isArray(toolData.value)
          ? toolData.value.join(', ')
          : toolData.value;
      }
      let labelText = toolData.tool.data.useKeyAsIndex
        ? toolData.tool.data.options.find(o => o.key === value).value
        : toolData.tool.label;

      let valueText = this.getValueText(value, toolData);
      return `${labelText}: ${valueText}`;
    },
    getValueText(value, toolData) {
      let valueText;
      switch (toolData.tool.data.type) {
        case this.SearchDataTypes.BOOLEAN:
          valueText = value
            ? toolData.tool.data.onLabel
            : toolData.tool.data.offLabel;
          break;
        case this.SearchDataTypes.CURRENCY:
          valueText = this.$options.filters.shortCurrency(value);
          break;
        case this.SearchDataTypes.DATE:
          valueText = this.$options.filters.date(value);
          break;
        case this.SearchDataTypes.DATETIME:
          valueText = this.$options.filters.dateTime(value);
          break;
        case this.SearchDataTypes.FLOAT:
          valueText = Math.round(this.$options.filters.float(value), 2);
          break;
        case this.SearchDataTypes.INTEGER:
          valueText = Math.round(this.$options.filters.integer(value), 2);
          break;
        case this.SearchDataTypes.MAP:
          if (
            toolData.tool.data.__typename ===
            SearchToolDataTypes.TREE_SELECT_DATA
          ) {
            let conferences = [
              value.conf_baseball,
              value.conf_basketball,
              value.conf_football,
              value.conf_track
            ];
            let conferenceNames = [];
            conferences
              .filter(val => val !== -2)
              .forEach(val => {
                toolData.tool.data.options.forEach(option => {
                  option.options.forEach(option2 => {
                    option2.options.forEach(option3 => {
                      if (option3.key == val) {
                        conferenceNames.push(option3.value);
                      }
                    });
                  });
                });
              });
            valueText = conferenceNames.join(', ');
          } else if (
            Array.isArray(toolData.value) &&
            toolData.tool.data.options
          ) {
            let vals = [];
            Object.values(toolData.value).forEach(val => {
              let o = toolData.tool.data.options.find(o => o.key === val + '');
              vals.push(o.value);
            });
            valueText = vals.join(', ');
          } else if (toolData.tool.data.options) {
            let option = toolData.tool.data.options.find(
              o => o.key === value + ''
            );
            if (option) {
              valueText = option.value;
            }
          }
          break;
        case this.SearchDataTypes.PERCENT:
          valueText = `${(Number(value) * 100).toFixed(2)}%`;
          break;
        case this.SearchDataTypes.PERCENT100:
          value = value / 100;
          valueText = `${(Number(value) * 100).toFixed(2)}%`;
          break;
        case this.SearchDataTypes.RANK:
          valueText = this.$options.filters.rank(value);
          break;
        case this.SearchDataTypes.STRING:
          valueText = value;
          break;
        default:
          valueText = value;
          break;
      }
      return valueText;
    },
    prepareMediaOfferedTagValue(toolData) {
      let selectedMediumIds = [];
      this.row.suppliers.forEach(supplier => {
        selectedMediumIds = concat(
          selectedMediumIds,
          intersection(
            toolData.value,
            supplier.mediums
              .filter(m => m.is_bookable)
              .map(m => m.superTag && m.superTag.id)
          )
        );
      });
      return uniq(selectedMediumIds).reduce((acc, cv, index) => {
        const options = toolData.tool.data.options;
        const option = options.find(option => option.key === cv);
        if (option) {
          return acc + (index ? ', ' : '') + option.value;
        }
      }, '');
    }
  }
};
</script>
