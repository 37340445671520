<template>
  <div class="flex justify-between items-center bg-white px-8 w-full shadow-2">
    <div>
      <a href="https://flytedesk.com">
        <icon :icon="flytedeskLogo" class="text-blue icon-xl" />
      </a>
    </div>
    <div class="flex">
      <router-link
        to="/auth/login"
        tag="div"
        class="px-4 py-5 hover:bg-light-gray hover:text-dark-blue text-dark-gray cursor-pointer"
      >
        Sign In
      </router-link>
      <a
        class="px-4 py-5 hover:bg-light-gray hover:text-dark-blue text-dark-gray cursor-pointer"
        href="https://www.flytedesk.com/buyer-signup"
      >
        Sign Up
      </a>
    </div>
  </div>
</template>

<script>
import { flytedeskLogo } from '@/vendor/icons';

export default {
  data() {
    return {
      flytedeskLogo
    };
  }
};
</script>
