var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"h-full"},[(_vm.audience)?_c('div',{staticClass:"flex h-full w-full relative"},[_c('div',{staticClass:"h-full border-right border-very-light-gray py-2"},[_c('notification-icon',{staticClass:"px-3",attrs:{"is-active":true,"icon":_vm.userIcon,"count":_vm.audience.summary.total_campuses,"border-side":"right","color":"blue","circle-color":"baby-blue"},on:{"click":function($event){return _vm.$emit('togglePanel')}}})],1),_c('div',{staticClass:"h-full w-panel py-6 flex flex-col overflow-hidden"},[_c('div',{staticClass:"flex justify-center font-semibold"},[_vm._v(" My Audience ")]),(_vm.audience.summary.total_campuses === 0)?_c('empty-state',{staticClass:"mt-10",scopedSlots:_vm._u([{key:"text",fn:function(){return [_vm._v(" Your audience is empty ")]},proxy:true},{key:"subtext",fn:function(){return [_vm._v(" Select campuses from the left to add to your audience. ")]},proxy:true}],null,false,4212231358)}):_c('div',[_c('div',{staticClass:"border-bottom border-very-light-gray pb-6 mb-4"},[(_vm.isLoading)?_c('loader-box',{staticClass:"p-6 w-full",attrs:{"height":"6em"}}):_c('div',{staticClass:"flex justify-around items-center text-center text-sm mt-4 mx-1/10"},[_c('tween',{attrs:{"value":_vm.audience.summary.total_campuses || 0},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var value = ref.value;
return [_c('div',{staticClass:"text-blue block text-xl font-bold inline"},[_vm._v(" "+_vm._s(_vm._f("shortNumber")(value))+" ")]),_c('div',{staticClass:"text-dark-silver"},[_vm._v("Campuses")])]}}],null,false,3168121621)}),_c('tween',{attrs:{"value":_vm.audience.summary.total_enrollment || 0},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var value = ref.value;
return [_c('div',{staticClass:"text-blue text-xl font-bold"},[_vm._v(" "+_vm._s(_vm._f("shortNumber")(value))+" ")]),_c('div',{staticClass:"text-dark-silver"},[_vm._v("Students")])]}}],null,false,2117547872)})],1)],1),_c('transition',{attrs:{"name":"fade"}},[_c('div',{staticClass:"flex justify-between items-center px-4"},[_c('div',{staticClass:"flex-grow mr-2 pb-4"},[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[_c('el-input',{attrs:{"placeholder":"Search selected campuses..."},on:{"input":_vm.onSearch}})],1)],1),_c('div',{staticClass:"flex items-center pb-4"},[_c('audience-mutation',{attrs:{"id":_vm.audience.id},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var removeCampuses = ref.removeCampuses;
return [_c('el-button',{staticClass:"border-gray group hover:border-dark-red p-2.5",on:{"click":function($event){return _vm.onRemoveCampuses(removeCampuses)}}},[_c('icon',{staticClass:"icon-lg",class:{
                        'text-dark-silver cursor-pointer group-hover:text-dark-red': _vm.hasSelections,
                        'text-silver cursor-not-allowed': !_vm.hasSelections
                      },attrs:{"icon":_vm.trashEmptyIcon}})],1)]}}],null,false,237305864)}),_c('div',{staticClass:"ml-2"},[_c('export-button',{attrs:{"disabled":!_vm.hasSelections},on:{"click":_vm.onExport}})],1)],1)])]),_c('booking-audience-summary-panel-table',{ref:"table",attrs:{"audience":_vm.audience}})],1)],1)]):_c('loader-box',{staticClass:"h-full w-full p-6",attrs:{"height":"100%"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }