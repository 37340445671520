<template>
  <section class="h-full">
    <loader-box v-if="!audienceId" height="100%" class="h-full" />
    <page-booking-audience v-else :audience-id="audienceId" />
  </section>
</template>

<script>
import PageBookingAudience from '@/modules/Demo/Audience/PageBookingAudience';
import { createAudience } from '@/graphql/audience/audienceMutations.graphql';

export default {
  components: {
    PageBookingAudience
  },
  data() {
    return {
      audienceId: null
    };
  },
  async mounted() {
    this.audienceId = localStorage.getItem('audience-id');
    if (!this.audienceId) {
      const { data } = await this.$apollo.mutate({
        mutation: createAudience,
        variables: { input: {} }
      });
      this.audienceId = data.mutateBuyer.mutateAudience.audience.id;
      localStorage.setItem('audience-id', this.audienceId);
    }
  }
};
</script>
