<template>
  <div class="table-empty-slot h-full p-5">
    <loader-content v-if="isLoading">
      <loader-table-rows :rows="loadingRows" />
    </loader-content>
    <template v-else-if="hasData">
      <slot name="empty">
        <div class="pad-lg">
          <slot>{{ emptyMessage }}</slot>
        </div>
      </slot>
    </template>
    <div v-else class="m-5">
      <el-alert
        type="error"
        :title="errorMessage"
        class="text-center"
        :closable="false"
      />
    </div>
  </div>
</template>

<script>
import { LoaderContent, LoaderTableRows } from '@/components/Core/Loaders';

export default {
  components: {
    LoaderContent,
    LoaderTableRows
  },
  props: {
    isLoading: Boolean,
    hasData: Boolean,
    loadingRows: {
      type: Number,
      default: 2
    },
    emptyMessage: {
      type: String,
      default: 'There are no records'
    },
    errorMessage: {
      type: String,
      default: 'There was a problem loading the records.'
    }
  }
};
</script>
